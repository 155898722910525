import { DefaultTheme } from "styled-components";
import { createTheme } from '@material-ui/core/styles';

declare module "styled-components" {
  export interface DefaultTheme {
    textColor: string;
    primaryColor: string;
    lightPrimaryColor: string;
    menuIconColor: string;
    secondaryColor: string;
    mutColor: string;
    backgroundColor: string;
    adminBackgroundColor: string;
    primaryColorHover: string;
    fontFamily: string;
  }
}

export const theme: DefaultTheme = {
  primaryColor: "#3699ff",
  primaryColorHover: "#115293",
  lightPrimaryColor: "#E1F0FF",
  menuIconColor: "#494b74",
  secondaryColor: "#dc004e",
  mutColor: "#B5B5C3",
  textColor: "#3f4254",
  backgroundColor: "#eef0f8",
  adminBackgroundColor: "#e8e9e8",
  fontFamily: "Poppins,Helvetica,sans-serif"
};

export const muiTheme = createTheme({
  typography: {
    fontFamily: 'Poppins,Helvetica,sans-serif',
    fontSize: 13,
  }
});