import React, { Suspense, lazy } from "react";
import {
    Switch,
    Redirect,
    BrowserRouter,
    Route,
} from "react-router-dom";
import {observer} from "mobx-react";
import Spinner from "src/pages/components/spinner";
import {userStore} from "../store";
import componentLoader from "./ComponentLoader";

const AdminRoutesContainer = lazy(() => componentLoader(() => import("./routes/admin/AdminRoutesContainer")));
const Login = lazy(() => componentLoader(() => import("src/pages/auth/Login")));
const EmailVerificationContainer = lazy(() => componentLoader(() => import("src/pages/auth/EmailVerificationContainer")));
const ClientRoutesContainer = lazy(() => componentLoader(() => import("./routes/client/ClientRoutesContainer")));
const ForgotPassword = lazy(() => componentLoader(() => import("src/pages/auth/ForgotPassword")));
const ResetPassword = lazy(() => componentLoader(() => import("src/pages/auth/ResetPassword")));
const PaymentSuccessContainer = lazy(() => componentLoader(() => import("src/pages/client/payment/PaymentSuccessContainer")));

//the new route must not be entirely contained in another route or include another route
//used by .replace()
export enum Routes {
    BASE = "/",
    AUTH = "/auth",
    
    CLIENT = "/client",
    DASH = "/dashboard",
    ASSES = "/assessments",
    GENERAL_INFO = "/general-info",
    EDUCATION = "/education",
    WORK_EXP = "/work-experience",
    LANGUAGE = "/language",
    ADAPTABILITY = "/adaptability",
    DOCUMENTS = "/documents",
    ELIGIBILITY_REPORT = "/eligibility-report",
    ELIGIBILITY_REPORT_FORM = "/form-eligibility-report",
    ABOUT = "/about",
    INITIAL_DOCUMENTS = "/initial-documents",
    ADDITIONAL_DOCUMENTS = "/additional-documents",
    
    ADMIN = "/admin",
    CLIENTSID = "/clients/",
    CLIENTS = "/clients",
    
    IM_PROGRAMS = "/im-programs",
    IM_PROGRAM_ID = "/im-programs/",
    IM_DOCUMENTS = "/im-documents", 
    IM_DOCUMENT_ID = "/im-documents/",
    
    IMMIGRATION_PACKAGES = "/immigration-packages",
    IMMIGRATION_PACKAGE_ID = "/immigration-packages/",
    
    ASSESSMENT_TEMPLATES = "/assessment-templates",
    ASSESSMENT_TEMPLATE_ID = "/assessment-templates/",
    
    PDC_EDITOR = "/prc-editor",
    SYSTEM_TEXTS = "/system-texts",
    SYSTEM_TEXT_ID = "/system-texts/",
    TRANSLATION_SETTINGS = "/translations",
    
    //pre-assessment flow
    WELCOME = "/welcome",
    ABOUT_THE_PROCESS = "/immigration-process",
    ACTIVATE_PROFILE = "/ea-payment",
    ABOUT_EA = "/about",
    PAYMENT_DECLINED = "/payment-declined",
    PROFILE_UNLOCK_DECLINED = "/profile-unlock-declined",
    PAYMENT_SUCCESS = "/payment-success",
    
    ACCOUNT_AND_BILLING = "/account-billing",
    IMMIGRATION_TEAM = "/immigration-team",
}

const Router = () => {
    const RedirectRoute = () => {
        if (userStore.isAuthorized) {
            return userStore.isClient ? <Redirect to={Routes.CLIENT}/> : <Redirect to={Routes.ADMIN}/>;
        }
        return <Redirect to={Routes.AUTH}/>;
    };

    const SideRoute = () => {
        if(!userStore.isAuthorized) return <Redirect to={Routes.AUTH}/>;
        else return userStore.isClient ? <Route path={Routes.CLIENT} component={ClientRoutesContainer}/> : <Route path={Routes.ADMIN} component={AdminRoutesContainer}/>;
    };
    
    return (
        <BrowserRouter>
            {userStore.loading ?
                <Spinner/> :
                <Suspense fallback={<Spinner/>}>
                    <Switch>
                        <Route path={Routes.AUTH} component={Login}/>
                        <Route exact path="/email-verification" component={EmailVerificationContainer}/>
                        <Route exact path="/forgot-password" component={ForgotPassword}/>
                        <Route exact path="/reset-password" component={ResetPassword}/>
                        <Route exact path={Routes.PAYMENT_SUCCESS} component={PaymentSuccessContainer}/>
                        {SideRoute()}
                        {RedirectRoute()}
                        
                        <Redirect from="*" to={"/"}/>
                    </Switch>
            </Suspense>}
        </BrowserRouter>
    );
}

export default observer(Router);
