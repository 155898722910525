import axios from 'axios';
import authHeader from './auth-header';
const faker = require('faker');

const URL_TEMP = process.env.REACT_APP_BACK_URL;

class UserService {

    getPublicContent() {
        return axios.get(URL_TEMP + 'test', { headers: authHeader() }); // , { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
    }

    async createAccount(userProps: { name: string, email: string, password: string, account_type: number, role?: string }) {
        let user = await axios.post(URL_TEMP + 'users', this.getUserProps(userProps), { headers: authHeader() }), account;
        const account_type = typeof userProps.account_type === 'string' ? parseInt(userProps.account_type) : userProps.account_type;
        user = user.data.data;

        switch (account_type) {
            case 1: {
                //statements; 
                break;
            }
            case 2: {
                let imgData = await this.getFileParams(user);
                console.log(imgData);
                let file = await axios.post(URL_TEMP + 'upload', imgData, { headers: authHeader() });
                file = await file.data;

                let gender = await this.postItem('genders', { name: this.getRandomStr(), sort_order: 1 });
                gender = await gender.data.data;
                console.log(gender);

                let department = await axios.post(URL_TEMP + 'departments', this.getDpartmentParams(), { headers: authHeader() });// getDpartmentParams
                department = await department.data.data;
                console.log(department);

                let emDes = await axios.post(URL_TEMP + 'employee-designations', this.getGenderParams(), { headers: authHeader() });
                emDes = await emDes.data.data;

                console.log(emDes);
                let employee = await axios.post(URL_TEMP + 'employees', this.getEmployeeProps(user, userProps, gender, emDes, department, file), { headers: authHeader() });
                console.log("employee", employee);
                account = employee.data.data;
                break;
            }
            case 3: {
                let department = await axios.post(URL_TEMP + 'departments', this.getDpartmentParams(), { headers: authHeader() });// getDpartmentParams
                // console.log(department); 
                let client = await axios.post(URL_TEMP + 'clients', this.getClientshProps(user, department.data.data), { headers: authHeader() });
                console.log(client);
                account = client.data.data;
                break;
            }
            default: {
                break;
            }
        }
        return account;
    }

    getUrl(){
        return URL_TEMP;
    }

    getAuthHeader(){
        return authHeader();
    }

    getUserBoard() {
        return axios.get(URL_TEMP + 'user', { headers: authHeader() });
    }

    getModeratorBoard() {
        return axios.get(URL_TEMP + 'mod', { headers: authHeader() });
    }

    getAdminBoard() {
        return axios.get(URL_TEMP + 'auth', { headers: authHeader() }); // oauth/authorize // admin
    }

    getClientBoard() {
        return axios.get(URL_TEMP + 'clients-test', { headers: authHeader() }); // oauth/authorize // admin
    }

    postItem(url: string, params = {}) {
        return axios.post(URL_TEMP + url, this.dynamicHttpParams(params), { headers: authHeader() }); // oauth/authorize // admin
    }

    clientAddProp(url: string, params = {}) {
        return axios.post(URL_TEMP + url, params, { headers: authHeader() });//.then(response => response).catch(err => this.hendleErrors(err)); // oauth/authorize // admin
    }


    deleteItem(url: string) {
        return axios.delete(URL_TEMP + url, { headers: authHeader() }); // oauth/authorize // admin
    }

    updateItem(url: string, params: {}) {
        return axios.put(URL_TEMP + url, this.dynamicHttpParams(params), { headers: authHeader() });
    }

    updateItemNew(url: string, data: {}) {
        return axios.put(URL_TEMP + url, data, { headers: authHeader() });
    }

    fetchAll(url: string, params?: {}) {
        return axios.get(URL_TEMP + url, { headers: authHeader(), params }); // oauth/authorize // admin
    }

    postRequest(url: string, params?: {}, config = {} as any) {
        return axios.post(URL_TEMP + url, params, {...config, headers: {...authHeader(), ...(config.headers ?? {})} });
    }

    getClientForm(url: string) {
        // clients/{client}/client-forms
        return axios.get(URL_TEMP + url, { headers: authHeader() }); // oauth/authorize // admin
    }

    dynamicHttpParams(params: any) {
        const httpParams = new URLSearchParams();
        Object.keys(params).forEach((itemKye: string) => httpParams.append(itemKye, params[itemKye]));
        console.log(httpParams);
        
        return httpParams;
    }

    protected hendleErrors(err: any){
        console.log("Errrrr was happened");
        console.log(err);
        return {
            status: 401,
            data: [],
            message: "Errrrr was happened", 
        };
    }

    protected getRandomStr() {
        return Math.random().toString(36).substring(7);
    }

    protected getUserProps(userProps: any) {
        const params = new URLSearchParams();

        params.append('account_type_id', userProps.account_type);
        params.append('email', userProps.email);
        params.append('name', userProps.name);
        params.append('password', userProps.password);
        params.append('system_language_id', '1');

        return params;
    }

    protected async getFileParams(user?: any) {
        const image = faker.image.avatar();
        const formData = new FormData();
        const blob = await this.toObjectUrl(image);
        console.log(blob);

        formData.append('file', blob);
        formData.append('user_id', user.id);
        return formData;
    }

    async toObjectUrl(url: string) {
        let response = await fetch(url);
        let data = await response.blob();
        let metadata = {
            type: 'image/jpeg'
        };
        let file = new File([data], "test.jpg", metadata);
        return file;
    }

    protected getGenderParams() {
        const params = new URLSearchParams();
        let r = Math.random().toString(36).substring(7);
        params.append('name', 'gender' + r);
        params.append('sort_order', '2');
        return params;
    }

    protected getDpartmentParams() {
        const params = new URLSearchParams();
        let r = Math.random().toString(36).substring(7);
        params.append('name', 'name' + r);
        params.append('color', '#fff');
        params.append('sort_order', '1');
        return params;
    }

    protected getEmployeeProps(user: any, userProps: any, gender: any, emDes: any, department: any, file: any) {
        const params = new URLSearchParams();

        params.append('user_id', user.id);
        params.append('name', userProps.name);
        params.append('email', user.email);
        params.append('gender_id', gender.id);
        params.append('employee_designation_id', emDes.id);
        params.append('department_id', department.id);
        params.append('mobile', "0544555555");
        params.append('picture_id', file.id);
        params.append('about', department.id);
        params.append('date_left', "2024-05-04");
        return params;
    }

    protected getClientshProps(user: any, department: any,) {

        const params = new URLSearchParams();

        params.append('user_id', user.id);
        params.append('department_id', department.id);
        params.append('first_name', user.name);
        params.append('last_name', "mysas");
        params.append('middle_name', "tester");

        params.append('client_status_id', "2");// check
        params.append('date_of_birth', "1990-11-08");
        params.append('important_info', "test importent inf-05-04");
        params.append('medical_condition_details', "feel greate");
        params.append('committed_crime_details', "2024-05i law keeper-04");
        params.append('is_single', "1");
        return params;
    }
}

export default new UserService();

