import React from 'react';

interface SpinnerProps {
    containerized?: boolean
}

const Spinner = (props: SpinnerProps) => {
    let containerStyle: {};
    let elementStyle: {};
    if(props.containerized){
        containerStyle = {
            width: '100%',
            height: "100%",
            position: 'relative'
        }
        elementStyle = {
            top: '50%',
            left: "50%",
            position: 'absolute',
            transform: "translate(-50%, -50%)"
        }
    }
    else{
        containerStyle = {
            width: '100%',
            height: "100vh",
            position: 'absolute'
        }
        elementStyle = {
            top: '30%',
            left: "50%",
            position: "fixed",
            transform: "translate(-50%, -50%)"
        }
    }
    
    return (
        <div style={containerStyle}>
            <div style={elementStyle}>
                <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        </div>
    );
}
export default Spinner;