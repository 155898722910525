
export const MonthShortName: {[key:string]: string} = {
  "Jan": "01",
  "Feb": "02",
  "Mar": "03",
  "Apr": "04",
  "May": "05",
  "Jun": "06",
  "Jul": "07",
  "Aug": "08",
  "Sep": "09",
  "Oct": "10",
  "Nov": "11",
  "Dec": "12"
}

export const checkStatusDateFormat = (date: string): boolean => {
  const dateTime = date.split(" ");
  if(dateTime.length !== 2) return false;
  const listDate = dateTime[0].split("/");
  if(listDate.length !== 3) return false;
  const listTime = dateTime[1].split(":");
  if(listTime.length !== 2) return false;
  return true;
}

export const getDate = (dateTime: Date, separator: string = "-"): string => {
  const date = dateTime.toString().split(" ");
  if(date.length === 2) return ""; // Invalid Date
  return date[3] + separator + MonthShortName[date[1]] + separator + date[2];
}

export const getDateTime = (dateTime: Date, separatorDate: string = "/", separatorTime: string = ":"): string => {
  const date = dateTime.toString().split(" ");
  if(date.length === 2) return ""; // Invalid Date
  const time = date[4].split(":");
  return date[2] + separatorDate + MonthShortName[date[1]] + separatorDate + date[3] + " " + time[0] + separatorTime + time[1];
}