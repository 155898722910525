import axios from "axios";
import UserService from "./user.service";

const URL_TEMP = process.env.REACT_APP_BACK_URL;

class AuthService {
    
    login(userProps: { email: string, password: string } | {temp_access_token: string, last_phone_digits?: string}) {
        return axios
            .post(URL_TEMP + "login", userProps)
            .then(response => {
                if (response.data.token) {
                    localStorage.setItem("token",response.data.token);
                }
                return response;
            })
            .catch(err => {
                throw err.response.data;
            })
    }

    getEmailVerificationData(userProps: {token: string}) {
        return axios
            .post(URL_TEMP + "getEmailVerificationData", userProps)
            .then(response => {
                return response.data;
            })
            .catch(err => {
                throw err.response.data;
            });
    }

    logout() {
        localStorage.removeItem("token");
    }

    register(userProps: { name: string, email: string, password: string }) {
        return axios.post(URL_TEMP + "register", userProps)
        .then(response => {
            console.log("::: register :: ", response);
            if (response.data.token) {
                localStorage.setItem("token",response.data);
            }
            return UserService.getAdminBoard();
        }).catch(err => this.hendleErrors(err));
    }

    getCurrentTokn() {
        let token = localStorage.getItem('token');  
        return (token) ? token : null;
    }

    hendleErrors(err: any){
        console.log("Errrrr was happened");
        console.log(err);
        return {
            status: 401,
            data: [],
            message: "Errrrr was happened", 
        };
    }
}

export default new AuthService();
