import { observable, computed, action } from 'mobx' 
import AssessmentTemplateType from 'src/types/assessment-templates';
import userService from 'src/_services/user.service';
import {Links, Meta} from "src/types/paginate";
import FileType from "../types/file-type";
 
 
const initTemplates: AssessmentTemplateType[] = [];
const initLinks: Links = {first: "", last: "", next: "", prev: ""};
const initMeta: Meta = {current_page: 0, from: 0, last_page: 0, path: "", per_page: 0, to: 0, total: 0};

class AssessmentTemplateModel { 

    @observable templates = initTemplates 
    @observable isFetching: boolean = false;

    @observable links = initLinks;
    @observable meta = initMeta;

    @action fetchTemplates = async (page: number, sortField?: string, searchText?: string) => {
        let response = await userService.fetchAll('assessment-templates',
            {
                page: page,
                sort: sortField,
                search: searchText
            }
        );
        if (response.status === 200) {
            this.templates = await response.data.data;
            this.links = await response.data.links;
            this.meta = await  response.data.meta;
        }
        else{
            console.log('fetchTemplates err: ', response);
        }
        this.isFetching = false;
    }

    @action getTemplate = async (id: number) => {
        const template = this.templates.find(t => t.id === id);
        if(template) return template;
        else{
            const response = await userService.fetchAll('assessment-templates/' + id);

            if (response.status === 200) return response.data.data;
            else console.log('getTemplate err: ', response);
        }
        return null;
    }
    
    @action deleteTemplate = async (templateId: number) => {
        const response = await userService.deleteItem('assessment-templates/' + templateId);
        
        if (response.status === 204) {
            this.templates = this.templates.filter(item => item.id !== templateId);
        }
    }
    
    @action insertTemplate = async (data: AssessmentTemplateType) => {
        if(!data.file) delete data.file;
        
        const formData = new FormData();
        Object.keys(data).forEach((key) => {
            if(key === 'file') formData.append(key, data.file, data.file_original_name);
            else if(data[key] !== undefined) formData.append(key, data[key].toString());
        })

        const headers = {
            "content-type": "multipart/form-data",
        };
        const response = await userService.postRequest('assessment-templates/store', formData, {headers});

        if (response.status === 200) {
            return response.data;
        }
        
        return 0;
    }
}

const assessmentTemplates = new AssessmentTemplateModel()
export default assessmentTemplates

