import React from 'react'

import assessmentTemplateStore from './AssessmentTemplateStore'
import userStore from './UserStore'
import employiesStore from './EmploeeStore'
import cards from './CardStore'
import payments from './PaymentStore'
import clientStore from './ClientStore'
import formStore from "./FormStore";
import appStore from "./AppStore"
import pdcStore from './PDCStore'
import brandStore from './BrandStore'
import stripeStore from './StripeStore'
import systemStore from './SystemStore'

export const stores = {
  assessmentTemplateStore,
  userStore,
  employiesStore,
  clientStore,
  pdcStore,
  formStore,
  cards,
  payments,
  appStore,
  brandStore,
  stripeStore,
  systemStore,
}

export {
  assessmentTemplateStore,
  userStore,
  employiesStore,
  clientStore,
  pdcStore,
  formStore,
  cards,
  payments,
  appStore,
  brandStore,
  stripeStore,
  systemStore,
}

export type UserStore = typeof userStore
export const StoreContext = React.createContext(stores);

export default function useStore() {
  return React.useContext(StoreContext);
}
