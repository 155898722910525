import { observable, computed, action } from 'mobx' 
import CCard from 'src/types/card-type';
import userService from 'src/_services/user.service';
 
const initCards: CCard[] = []; 
class CardModel { 

    @observable cards = initCards 
    @observable isFetching:boolean = false; 
    
    @computed get hasCard() { return  this.cards.length? true: false; }
    @computed get getCards() { return this.cards; }
 

    @action fetchCard = async (id: number) => {
        
        if(this.isFetching === true) return;
        this.isFetching = true;
        let clientsCard = await userService.fetchAll('clients/'+id+"/cards")

        if (await clientsCard.status === 200) {

            if (clientsCard.data && clientsCard.data.data) {
                this.cards = await clientsCard.data.data;
            } else {
                this.cards = await clientsCard.data;
            } 
            console.log(clientsCard);
        }
        this.isFetching = false;
    }

    @action setCard(cards: CCard[]) {
        this.cards = cards;
    }

    @action getCard(id: number) {
        return this.cards.filter(card => card.id === id);
    }

    @action updateCard = async (clientId: number, cardId: number, userParams: any) => {

        const response = await userService.updateItem('clients/' + clientId+"/cards/"+ cardId, userParams);
        console.log(response);
        if (response.status === 200){
            this.cards = this.cards.filter(item => item.id !== userParams.id);
            let newCard = response.data && response.data.data? response.data.data: response.data;
            this.cards.push(newCard);
            console.log(this.cards.length);
        }
    }


    @action delCard = async (clientId: number, cardId: number) => {
        
        const response = await userService.deleteItem('clients/' + clientId+"/cards/"+ cardId);
        console.log(this.cards.length);
        if (response.status === 204) {
            this.cards = this.cards.filter(item => item.id !== cardId);
            console.log(this.cards.length);
        }
    }
}

const cards = new CardModel()
export default cards

