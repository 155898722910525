import {action, observable} from 'mobx'
import userService from "../_services/user.service";

interface ISystemText {
    id: number;
    key: string;
    [key: string]: any,
}

class SystemStore {
    @observable systemTexts = new Map<string, ISystemText>();
    systemTextsLoading = null as Promise<any>|null;
    
    constructor() {
        this.loadSystemTexts();
    }
    
    @action loadSystemTexts = (client_id?: number) => {
        if(this.systemTextsLoading) this.systemTextsLoading.finally( async () => await this.p_loadSystemTexts(client_id));
        else this.systemTextsLoading = this.p_loadSystemTexts(client_id);
    }

    @action p_loadSystemTexts = (client_id?: number) => {
        return userService.postRequest(`getSystemTexts${client_id ? '/'+client_id : ''}`)
            .then((result) => {
                if(Array.isArray(result.data.data)){
                    result.data.data.forEach((textElement: ISystemText) => {
                        this.systemTexts.set(textElement.key, textElement)
                    })
                }
            }).catch((err) => {
                //throw err.response?.data;
            });
    }
    
    @action getTextByKey(key: string, lang: string){
      const element = this.systemTexts.get(key);
      return element ? element[lang] || element['english'] : '';
    }
    
    @action systemTextSearch(params: {[key: string]: any}){
        return userService.postRequest(`system-text/search`, params)
            .then((result) => {
                return result.data;
            }).catch((err) => {
                throw err.response.data;
            });
    }
    
    @action systemTextStore(params: {[key: string]: any}){
        return userService.postRequest(`system-text/store`, params)
            .then((result) => {
                return result.data;
            }).catch((err) => {
                throw err.response.data;
            });
    }
    
    @action systemTextShow(id: any){
        return userService.postRequest(`system-text/show`, {id})
            .then((result) => {
                return result.data;
            }).catch((err) => {
                throw err.response.data;
            });
    }
}

const systemStore = new SystemStore();

export default systemStore