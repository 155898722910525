import {action, computed, observable} from 'mobx'
import Client, {ClientType} from 'src/types/client-type'
import userService from 'src/_services/user.service';
import User from "../types/user-type";
import moment, {now} from "moment";
import appStore from "./AppStore";
import {Links, Meta} from "src/types/paginate";

const initClients: Client[] = [];
const initCurrentClient = {} as Client;
const initCRMStatuses = [] as {name: string}[];
const initLinks: Links = {first: "", last: "", next: "", prev: ""};
const initMeta: Meta = {current_page: 0, from: 0, last_page: 0, path: "", per_page: 0, to: 0, total: 0};

class ClientStore {
    @observable clients = initClients;
    @observable totalClients = 0;
    @observable currentClient = initCurrentClient;
    @observable CRMStatuses = initCRMStatuses;
    @observable links = initLinks;
    @observable meta = initMeta;
    @observable isFetching = false;
    @observable anotherFetch = 0 as any;

    @computed get getClients() { return this.clients; }
    @computed get getTotal(){ return this.meta.total; }
    @action addClient = (newCls: Client) => { this.clients.push(newCls); }

    @computed get getCurrentClient() {return this.currentClient; }

    @computed get canViewBillingPage() {
        return this.currentClient && (!this.currentClient.client_type || this.currentClient.client_type === ClientType.Account) && this.currentClient.isStripeSubscriptionExist; 
    }
    
    @action setCurrentClient = (client: Client) => {
        this.currentClient = client;
        if(client){
            appStore.showImmigrationPackagesForClient = !!client.immigration_package_id || Date.parse(client.created_at) > Date.parse("2022-06-05");
        }
        
        //check stripe subscription
        userService.postRequest(`stripe/client/${client.id}/checkSubscription`)
          .then((result) => {
              return this.currentClient.isStripeSubscriptionExist = result.data.subscriptionExist;
          }).catch((err) => {
             console.log(err.response.data);
          })
    }

    @action clearAllData = () => {
        this.clients = initClients;
        this.totalClients = 0;
        this.currentClient = initCurrentClient;
        this.CRMStatuses = initCRMStatuses;
        this.links = initLinks;
        this.meta = initMeta;
        this.isFetching = false;
        if(this.anotherFetch !== 0) {
            clearTimeout(this.anotherFetch);
            this.anotherFetch = 0;
        }
    }

    @action fetchClientToPage = async (page: number,
                                       per_page: number,
                                       sortField?: string,
                                       searchText?: string,
                                       programs?: {id: number, name: string}[] | null | string,
                                       CRMStatuses?: {name: string}[] | null | string,
                                       assessmentTypes?: {name: string, value: boolean}[] | null | string,
                                       assessmentUploads?: {name: string, value: boolean}[] | null | string,
                                       pdcs?: {name: string, value: boolean}[] | null | string,
                                       subsStatuses?: {name: string, value: string}[] | null | string, ) => {
        if(this.isFetching) {
            if(this.anotherFetch !== 0) clearTimeout(this.anotherFetch);
            this.anotherFetch = setTimeout(() => {
                this.fetchClientToPage(page, per_page, sortField, searchText, programs, CRMStatuses, assessmentTypes, assessmentUploads, pdcs, subsStatuses);
            }, 200);
            return;
        }
        if(this.anotherFetch !== 0) {
            clearTimeout(this.anotherFetch);
            this.anotherFetch = 0;
        }
        this.isFetching = true;
        let response = await userService.fetchAll('clients',
            {
                page: page,
                per_page: per_page,
                sort: sortField,
                search: searchText,
                programs: programs ? ((typeof programs === "string") ? programs : programs?.map((e) => e.id)) : null,
                CRMStatuses: CRMStatuses ? ((typeof CRMStatuses === "string") ? CRMStatuses : CRMStatuses?.map((e) => e.name)) : null,
                assessmentTypes: assessmentTypes ? ((typeof assessmentTypes === "string") ? assessmentTypes : assessmentTypes?.map((e) => e.value)) : null,
                assessmentUploads: assessmentUploads ? ((typeof assessmentUploads === "string") ? assessmentUploads : assessmentUploads?.map((e) => e.value)) : null,
                pdcs: pdcs ? ((typeof pdcs === "string") ? pdcs : pdcs?.map((e) => e.value)) : null,
                subsStatuses: subsStatuses ? ((typeof subsStatuses === "string") ? subsStatuses : subsStatuses?.map((e) => e.value)) : null
            }
        );
        if (response.status === 200) {
            if (response.data) {
                this.clients = await response.data.items;
                this.totalClients = await response.data.total;
            }
        }
        this.isFetching = false;
    }

    @action fetchClient = async ( id:number) => {
        this.isFetching = true;
        let clients = await userService.fetchAll('clients/' + id);
        if (clients.status === 200) {
            if (clients.data && clients.data.data) {
                this.clients = [await clients.data.data];
            } else {
                this.clients = [await clients.data];
                console.log("exception at getting clients");
            }
        }
        this.isFetching = false;
    }

    @action updateClient = async (id: number, userParams: any) => {
        const response = await userService.updateItem('clients/' + id, userParams);
        // console.log(response);
        if (response.status === 200){
            this.clients = this.clients.filter(item => item.id !== userParams.id);
            let newCls = response.data && response.data.data? response.data.data: response.data;
            this.clients.push(newCls);
            // console.log(this.clients.length);
        }
    }

    @action delClient = async (id: number) => {
        const response = await userService.deleteItem('clients/' + id);
        // console.log(this.clients.length);
        if (response.status === 204) {
            this.clients = this.clients.filter(item => item.id !== id);
            // console.log(this.clients.length);
        }
    }

    @action getCRMStatuses = async () => {
        const response = await userService.fetchAll('clients-crm-statuses');
        if (response.status === 200) {
            this.CRMStatuses = (response.data.statuses as string[]).filter((e) => Boolean(e)).map((e) => {
                return {name : e}
            });
        }
    }

    @action setImmigrationProgram = async (client_id: number, immigration_program_id: number) => {
        const response = await userService.updateItemNew('clients/' + client_id + '/set-immigration-program', {immigration_program_id: immigration_program_id});
        return response.status;
    }

    @action getActivity = async (client_id: number) => {
        const response = await userService.fetchAll('clients/' + client_id + '/get-activity');
        if(response.status === 200) return response.data.data;
    }

    @action setNewEmail = (email: string, verified?: boolean) => {
        let newUser = {...this.currentClient.user} as User;
        newUser.email = email;
        if(verified) newUser.email_verified_at = moment(now()).format('YYYY-MM-DD HH:mm:ss');
        this.currentClient.user = newUser;
        this.currentClient.user.email = email;
    }

    @action sendEmailVerificationRequest = (send_to_email: string, save_new_email: boolean = false) => {
        return userService.postRequest(`clients/${this.currentClient.id}/email-verification-request`, {
            send_to_email: send_to_email,
            save_new_email: save_new_email,
            front_url: window.location.origin,
        }).then((result) => {
            if(save_new_email){
                this.setNewEmail(send_to_email, true);
            }
            this.setVerificationCodeSentAt(result.data.verification_code_sent_at);
            return result.data;
        }).catch((err) => {
            throw err.response.data;
        })
    }

    @action clickOnActivateAssessmentButton = (data: any) => {
        return userService.postRequest(`clients/${this.currentClient.id}/click-activate-assessment`, data);
    }

    @action clickOnBookConsultationButton = (data: any) => {
        return userService.postRequest(`clients/${this.currentClient.id}/click-book-consultation`, data);
    }

    @action sendActivateAssessmentBillingInfoRequest = (params: any) => {
        return userService.postRequest(`clients/${this.currentClient.id}/activate-assessment`, params)
            .then((result) => {
                return result.data;
            }).catch((err) => {
                throw err.response.data;
            })
    }

    @action sendPreAssessmentPercentOfFilledFieldsRequest = () => {
        return userService.postRequest(`clients/${this.currentClient.id}/assessment-fields-percentage`)
            .then((result) => {
                return result.data;
            }).catch((err) => {
                throw err.response.data;
            })
    }
    
    @action publishEligibilityForm = () => {
        return userService.postRequest(`clients/${this.currentClient.id}/publishEligibilityForm`)
          .then((result) => {
              return result.data;
          }).catch((err) => {
              throw err.response.data;
          }) 
    }

    @action changeEligibilityFormLanguage = () => {
        return userService.postRequest(`clients/${this.currentClient.id}/changeEligibilityFormLanguage`)
          .then((result) => {
              return result.data;
          }).catch((err) => {
              throw err.response.data;
          })
    }

    @action sendApproveEligibilityReportRequest = (params: any) => {
        return userService.postRequest(`clients/${this.currentClient.id}/approveER`, params)
            .then((result) => {
                return result.data;
            }).catch((err) => {
                throw err.response.data;
            })
    }

    @action setVerificationCodeSentAt = (at: string) => {
        this.currentClient = {
            ...this.currentClient,
            user: {
                ...this.currentClient.user,
                verification_code_sent_at: at
            }
        } as Client;
    }

    @action checkUnlockProfilePayment = () => {
        return userService.postRequest(`clients/${this.currentClient.id}/checkUnlockProfilePayment`)
            .then((result) => {
                return result.data;
            }).catch((err) => {
                throw err.response.data;
            })
    }

    @action checkAfterVerificationQuestionnairePercentage = (client_id: number | string) => {
        return userService.postRequest(`clients/${client_id}/checkAfterVerificationQuestionnairePercentage`)
            .then((result) => {
                return result.data;
            }).catch((err) => {
                throw err.response.data;
            })
    }

    @action acceptConsultationAgreement = () => {
        return userService.postRequest(`clients/${this.currentClient.id}/acceptConsultationAgreement`)
            .then((result) => {
                this.currentClient.consultation_agreement_date = new Date().toISOString();
            }).catch((err) => {
                throw err.response.data;
            })
    }

    @action checkEmailVerification = () => {
        return userService.postRequest(`clients/${this.currentClient.id}/checkEmailVerification`)
            .then((result) => {
                if(result.data.email_verified_at && this.currentClient.user) this.currentClient.user.email_verified_at = result.data.email_verified_at;
            }).catch((err) => {
                throw err.response.data;
            })
    }
}

const clientStore = new ClientStore()
export default clientStore