/*
* For add new language need to do:
* 
* FRONT
* add value to _fullListOfLangInDifferent
* add value to _fullListOfLangInEnglish
* add value to _fullListOfLangKeys
* add value to _langNamesForUserSelect
* add value to _langKeysForUserSelect
* add field to long_description_ in PDCFormField
* add field to long_description_ in FormField
* add field to long_description_ in FormFieldSend
* add field to long_name_ in ImmigrationProgram
* add name and code to ENV
* 
* BACK
* add field to $casts and $fillable to FormField Model
* add field to $fillable to ImmigrationProgram Model
* add field to getPDCEditorData (FormFieldController)
* add field to ImmigrationProgramResource
* add to LangController
* add to LanguageConst
* add fields to Translate
* add fields to SystemTexts
* make migration
* 
* 
* */

import systemStore from "src/store/SystemStore";



const _defaultLang = process.env.REACT_APP_BRAND_DEFAULT_LANG || "en";

const _fullListOfLangKeys = (process.env.REACT_APP_FULL_LANG_KEYS || "en").split('|');
const _fullListOfLangInDifferent = (process.env.REACT_APP_FULL_LANG_IN_DIFF || "English").split('|');
const _fullListOfLangInEnglish = (process.env.REACT_APP_FULL_LANG_IN_ENGLISH || "English").split('|');

const _langKeysForUserSelect = (process.env.REACT_APP_BRAND_LANG_KEYS || "en").split('|');
const _langNamesForUserSelect = _langKeysForUserSelect.map((key) => _fullListOfLangInDifferent[_fullListOfLangKeys.indexOf(key)]);
const _langNamesForUserInEnglish = _langKeysForUserSelect.map((key) => _fullListOfLangInEnglish[_fullListOfLangKeys.indexOf(key)]);

const currentLang = localStorage.getItem("lang") || _defaultLang;

// @ts-ignore
const translate = new Map(window.langs[currentLang]) as Map<string, string>;

const getTranslation = function(key: string, params: {[key: string]: string}|null = null) {
    if (key && key.substring(0, 3)  === 'fr:' && currentLang === 'en'){
        return '';
    }
    else if(translate.get(key) && translate.get(key)?.substring(0, 3) === 'en:' && currentLang !== 'en')
    {
        return '';
    }
    let translated = translate.get(key) || key;

    if(params) translated = substituteParams(translated, params);

    return translated;
};

const substituteParams = function(text: string, params: {[key: string]: string} = {}){

    const lookForParamsRegEx = /\$[{[](.*?)[}\]]/g; //ex: "test ${param} test $[param2]"
    const lookForParamNameRegEx = /[^${}[\]]+/g;

    const strParams = text.match(lookForParamsRegEx);

    if(Array.isArray(strParams)){
        strParams.forEach((strParam) => {
            let paramName = strParam.match(lookForParamNameRegEx);
            let replaceTo = '';

            if(Array.isArray(paramName) && paramName[0] && params[paramName[0]]){
                replaceTo = params[paramName[0]];
            }

            text = text.replace(strParam, replaceTo);
        })
    }

    return text;
}

const getTranslationWithParamsBySystemTextKey = function(key: string, params: {[key: string]: string} = {}) {
    let translated = systemStore.getTextByKey(key, _fullListOfLangInEnglish[_fullListOfLangKeys.indexOf(currentLang)].toLowerCase());
    if(!translated) return '';

    translated = substituteParams(translated, params);

    return translated;
};

const Lang = {
    get: getTranslation,
    getByKey: getTranslationWithParamsBySystemTextKey,
    getLangNames: () => _langNamesForUserSelect,
    getLangEnglishNames: () => _langNamesForUserInEnglish,
    getLangKeys: () => _langNamesForUserSelect,
    getLangIndex: (key: string) => _langKeysForUserSelect.indexOf(key),
}

const AdminLang = {
    get: (key: string) =>  translate.get(key) || key,
    getLangNames: () => _fullListOfLangInDifferent,
    getLangKeys: () => _fullListOfLangKeys,
    getLangEnglishNames: () => _fullListOfLangInEnglish,
    getLangIndex: (key: string) => _fullListOfLangKeys.indexOf(key),
}

const setLang = (langIndex: number) => {
    localStorage.setItem("lang", _langKeysForUserSelect[langIndex]);
}

const getLang = ():string => {
    return currentLang;
}

export {
    Lang,
    AdminLang,
    setLang,
    getLang,
}

export default Lang;