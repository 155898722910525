import {action, computed, observable} from 'mobx'
import ImmigrationDocumentStatus from "../types/immigration_document_status_type";
import axios from 'axios';

interface IClientsFilter {
    searchText: string;
    sortField: string;
    selectedPrograms: {id: number, name: string}[];
    selectedSubsStripeStatuses: {name: string, value: string}[];
    selectedCRMStatuses: {name: string}[];
    selectedAssessmentTypes: {name: string, value: boolean}[];
    selectedAssessmentUploaded: {name: string, value: boolean}[];
    selectedPDC: {name: string, value: boolean}[];
    initialLoading: boolean;
    is_mount: boolean;
}

interface IDocumentsFilter {
    searchText: string;
    sortField: string;
    selectedPrograms: {id: number, name: string}[];
    selectedStatuses: ImmigrationDocumentStatus[];
    initialLoading: boolean;
    is_mount: boolean;
}

let responseInterceptorId: number | null = null;

const initialClientsFilter = {initialLoading: true, sortField: '-created_at'} as IClientsFilter;
const initialDocumentsFilter = {initialLoading: true} as IDocumentsFilter;

class AppStore {
    @observable asideAdminMenu = false;
    @observable asideMenu = true;
    @observable quickUserMenu = false;
    @observable showImmigrationPackagesForClient = false;
    @observable notify: Function = () => {}; // set in other place { Toastify }
    @observable clientsFilter = initialClientsFilter;
    @observable documentsFilter = initialDocumentsFilter;
    
    @observable demoAccount = initialDocumentsFilter;
    @observable successPaymentModal = false;
    
    constructor() {
        this.init();
    }

    @computed get getAsideMenu() {return this.asideMenu; }
    
    @action clearAllData = () => {
        this.clientsFilter = initialClientsFilter;
        this.documentsFilter = initialDocumentsFilter;
    }
    
    @action init = () => {
        if (responseInterceptorId !== null) {
            axios.interceptors.request.eject(responseInterceptorId);
            responseInterceptorId = null;
        }
        const th = this;
        responseInterceptorId = axios.interceptors.response.use(
          function (response) { return response },
          function (error) {
              const errorData = error?.response?.data;
              if(errorData){
                  console.log('error', errorData.message);
                  if(errorData.exception === "App\\Exceptions\\VisibleException"){
                      th.notify("FAIL", errorData.message);
                  }
              }
              return Promise.reject(error);
          });
    }
}

const appStore = new AppStore()
export default appStore