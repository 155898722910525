import { action } from 'mobx' 
import userService from 'src/_services/user.service';
import clientStore from "./ClientStore";
import BillingData, {StripeReactivateUrl} from "../types/biling_data_type";
//import appStore from "./AppStore";
import Lang from "../assets/lang";

const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
 
class StripeModel {
    @action getAccountAndBillingData = () => {
        return userService.postRequest(`stripe/client/${clientStore.currentClient.id}/getAccountAndBillingData`, {
          success: window.location.href,
          cancel: window.location.href
        })
          .then((result) => {
              return result.data as BillingData;
          }).catch((err) => {
              console.log(err.response)
              //appStore.notify('FAIL');
              throw err;
          })
    }

  @action getReactivateSubscriptionUrl = (successUrl: string, cancelUrl: string) => {
    return userService.postRequest(`stripe/client/${clientStore.currentClient.id}/getReactivateSubscriptionUrl`, {
      success: successUrl,
      cancel: cancelUrl,
    })
      .then((result) => {
        return result.data as StripeReactivateUrl;
      }).catch((err) => {
        console.log(err.response)
        throw err;
      })
  }

    @action getPriceSymbol = (currency: string) => {
      if(currency === 'eur') return '€';
      else if(currency === 'gbp') return '£';
      else return '$';
    }
  
    @action getPrice = (amount: number) => {
      return amount/100;
    }


    @action getBilledInterval = (interval: string) => {
      if(interval === 'month') return 'monthly';
      else return 'annual';
    }

    @action getRenewsDate = (timestamp: number) => {
      const date = new Date(timestamp*1000);
      return `${Lang.get(monthNames[date.getMonth()])} ${date.getDate()}, ${date.getFullYear()}`;
    }
}

const stripe = new StripeModel()
export default stripe

