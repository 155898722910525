import Client from "./client-type";
import Role from "./user_role_type";
import Permission from "./user_permission_type";
import {Employee} from "./employee-type";

interface User {
    id: number,
    system_language_id?: number,
    account_type_id: number,
    name: string,
    email: string
    created_at: string,
    updated_at: string,
    email_verified_at: string,
    client: Client,
    employee?: Employee,
    power_link_id: string,
    roles: Role[],
    permissions: Permission[],
    status_id: number,
    verification_code_sent_at: string|null;
    token?: string,

    phone?: string,
}

export default User;

export enum UserRole {
    Admin = 1,
    Employee = 2,
    Client = 3,
    ClientVisitor = 4,
    ClientImmigrant = 5,
    AccountManager = 6,
    AccountTeamLeader = 7,
    ComplianceManager = 8,
    RCIC = 9,
    CustomerSupportManager = 10,
    SupportTeamLeader = 11
}