import React, {useEffect} from "react";
import {toast, ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {appStore} from "src/store";

const useMountEffect = (fun: any) => useEffect(fun, []);

const Toastify = () => {
  const notify = (type: string, msg?: string) => {
    switch (type) {
      case "SUCCESS":
        toast.success("Data was saved !");
        break;
      case "INFO":
        toast.info(msg ? msg : "Somthing went wrong !");
        break;
      case "FAIL":
        toast.error(msg ? msg : "Somthing went wrong !");
        break;

      case "WARNING":
        toast.warning(msg ? msg : "Somthing went wrong !");
        break;
      default:
        toast.info(msg ? msg : "Somthing went wrong !");
    }
  };
  
  useMountEffect(() => {
    toast.configure();
    appStore.notify = notify;
  })
  
  return ( 
    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  )
}
export default Toastify;