import { observable, computed, action } from 'mobx' 
import CPayment from 'src/types/payment-type';
import userService from 'src/_services/user.service';
 
 
const initpayment: CPayment[] = []; 

class PaymentModel { 

    @observable payments = initpayment 
    @observable isFetching:boolean = false; 
    
    @computed get hasPayment() { return  this.payments.length? true: false; }
    @computed get getPayments() { return this.payments; }
 

    @action fetchPayment = async (id: number) => {
        
        if(this.isFetching === true) return;
        this.isFetching = true;
        let clientsPayments = await userService.fetchAll('clients/'+id+"/payments")

        if (await clientsPayments.status === 200) {

            if (clientsPayments.data && clientsPayments.data.data) {
                this.payments = await clientsPayments.data.data;
            } else {
                this.payments = await clientsPayments.data;
            } 
            console.log(clientsPayments);
        }
        this.isFetching = false;
    }

    @action setPayment(payment: CPayment[]) {
        this.payments = payment;
    }

    @action getPayment(id: number) {
        return this.payments.filter(Payment => Payment.id === id);
    }

    @action updatePayment = async (clientId: number, paymentId: number, userParams: any) => {

        const response = await userService.updateItem('clients/' + clientId+"/payments/"+ paymentId, userParams);
        console.log(response);
        if (response.status === 200){
            this.payments = this.payments.filter(item => item.id !== userParams.id);
            let newPayment = response.data && response.data.data? response.data.data: response.data;
            this.payments.push(newPayment);
            console.log(this.payments.length);
        }
    }


    @action delPayment = async (clientId: number, paymentId: number) => {
        
        const response = await userService.deleteItem('clients/' + clientId+"/payments/"+ paymentId);
        console.log( this.payments.length);
        if (response.status === 204) {
            this.payments =  this.payments.filter(item => item.id !== paymentId);
            console.log( this.payments.length);
        }
    }
}

const payments = new PaymentModel()
export default payments

