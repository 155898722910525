import {action, computed, observable} from 'mobx'
import userService from 'src/_services/user.service';
import {
    CreatePackageRequest,
    EditPackageRequest,
} from "../types/data_requests";
import Brand from "../types/brand_type";
import ImmigrationPackage from "../types/immigration_package_type";
import clientStore from "./ClientStore";

export interface Links {
    first: string,
    last: string,
    next: string,
    prev: string,
}

export interface Meta {
    current_page: number,
    from: number,
    last_page: number,
    path: string,
    per_page: number,
    to: number,
    total: number,
}

const initLinks: Links = {first: "", last: "", next: "", prev: ""};
const initMeta: Meta = {current_page: 0, from: 0, last_page: 0, path: "", per_page: 0, to: 0, total: 0};

class BrandStore {
    @observable brands = [] as Brand[];
    @observable immigrationPackages = [] as ImmigrationPackage[];
    @observable brandPackages = [] as ImmigrationPackage[];
    
    @observable links = initLinks;
    @observable meta = initMeta;
    @observable isFetching = false;
    @observable anotherFetch = 0 as any;
    
    @computed get getTotal(){ return this.meta.total; }

    @action clearAllData = () => {
        this.brands = [] as Brand[];
        this.immigrationPackages = [] as ImmigrationPackage[];
        this.links = initLinks;
        this.meta = initMeta;
        this.isFetching = false;
        if(this.anotherFetch !== 0) {
            clearTimeout(this.anotherFetch);
            this.anotherFetch = 0;
        }
    }

    @action fetchBrands = async () => {
        let response = await userService.fetchAll('brands-all');
        if (response.status === 200) {
            if (response.data && response.data.data) {
                this.brands = await response.data.data;
            } else {
                this.brands = await response.data;
                console.log("exception at getting brands");
            }
        }
    }
    
    @action fetchPackagesToPage = async (page: number, sortField?: string, searchText?: string) => {
        if(this.isFetching) {
            if(this.anotherFetch !== 0) clearTimeout(this.anotherFetch);
            this.anotherFetch = setTimeout(() => {
                this.fetchPackagesToPage(page, sortField, searchText)
            }, 200);
            return;
        }
        if(this.anotherFetch !== 0) {
            clearTimeout(this.anotherFetch);
            this.anotherFetch = 0;
        }
        this.isFetching = true;
        let response = await userService.fetchAll('immigration/packages',
          {
            page: page,
            sort: sortField,
            search: searchText
          }
        );
        if (response.status === 200) {
            if (response.data && response.data.data) {
                this.immigrationPackages = await response.data.data;
                this.links = await response.data.links;
                this.meta = await  response.data.meta;
            } else {
                this.immigrationPackages = await response.data;
                console.log("exception at getting packages");
            }
        }
        this.isFetching = false;
    }

    @action getDataForCreatePackage = async () => {
        const response = await userService.fetchAll('immigration/packages/data-for-new');
        if (response.status === 200){
            return response.data as CreatePackageRequest;
        }
        else return null;
    }

    @action getDataForEditPackage = async (package_id: number) => {
        const response = await userService.fetchAll('immigration/packages/data-for-edit/'+package_id);
        if (response.status === 200){
            return response.data as EditPackageRequest;
        }
        else return null;
    }

    @action savePackage = async (id: number, im_package: ImmigrationPackage) => {
        const response = await userService.updateItemNew(id === 0 ? 'immigration/packages/create' : ('immigration/packages/update/' + id), im_package);
        if (response.status === 201 || response.status === 200){
            this.immigrationPackages = this.immigrationPackages.filter(item => item.id !== id);
            let newDocument: ImmigrationPackage = response.data && response.data.data? response.data.data: response.data;
            this.immigrationPackages.push(newDocument);
            return newDocument;
        }
        return null;
    }

    @action fetchBrandPackages = async () => {
        let response = await userService.fetchAll('immigration/packages/available-packages');
        if (response.status === 200) {
            if (response.data && response.data.data) {
                this.brandPackages = await response.data.data;
            } else {
                this.brandPackages = await response.data;
            }
        }
    }

    @action updateSelectedPackage = async (client_id: number, package_id: number) => {
        const response = await userService.postRequest('immigration/packages/select', {
            client_id,
            package_id,
        });
        if (response.status === 201 || response.status === 200){
            clientStore.currentClient.requested_immigration_package_id = package_id;
            clientStore.currentClient.requested_immigration_package_at = (new Date()).toUTCString();
        }
        return null;
    }
    
/*
    @action fetchAllProgram = async () => {
        let response = await userService.fetchAll('immigration/programs/list');
        if (response.status === 200) {
            if (response.data && response.data.data) {
                this.programs = await response.data.data;
            } else {
                this.programs = await response.data;
                console.log("exception at getting programs");
            }
        }
    }

    @action fetchAllProgramAsFilter = async () => {
        let response = await userService.fetchAll('immigration/programs/list');
        if (response.status === 200) {
            if (response.data && response.data.data) {
                this.filterPrograms = await response.data.data;
                this.filterPrograms = [{id: 0, name: "(Blank)"} as ImmigrationProgram, ...this.filterPrograms];
            } else {
                this.filterPrograms = await response.data;
                console.log("exception at getting programs");
            }
        }
    }

    @action fetchPDCEditorData = async (client_id: number) => {
        let response = await userService.fetchAll('immigration/documents/pdc-editor/' + client_id);
        if (response.status === 200) {
            return response.data as PDCEditorDataRequest;
        }
        else return null;
    }

    @action fetchAllDocumentStatuses = async () => {
        let response = await userService.fetchAll('immigration/documents/statuses');
        if (response.status === 200) {
            if (response.data && response.data.data) {
                this.documentStatuses = await response.data.data;
            } else {
                this.documentStatuses = await response.data;
                console.log("exception at getting programs");
            }
        }
    }

    @action fetchDocumentToPage = async (page: number,
                                         sortField?: string,
                                         searchText?: string,
                                         programs?: {id: number, name: string}[] | null | string,
                                         statuses?: ImmigrationDocumentStatus[] | null | string) => {
        if(this.isFetching) {
            if(this.anotherFetch !== 0) clearTimeout(this.anotherFetch);
            this.anotherFetch = setTimeout(() => {
                this.fetchDocumentToPage(page, sortField, searchText, programs, statuses)
            }, 200);
            return;
        }
        if(this.anotherFetch !== 0) {
            clearTimeout(this.anotherFetch);
            this.anotherFetch = 0;
        }
        this.isFetching = true;
        let response = await userService.fetchAll('immigration/documents',
          {
              page: page,
              sort: sortField,
              search: searchText,
              programs: programs ? ((typeof programs === "string") ? programs : programs?.map((e) => e.id)) : null,
              statuses: statuses ? ((typeof statuses === "string") ? statuses : statuses?.map((e) => e.id)) : null
          }
        );
        if (response.status === 200) {
            if (response.data && response.data.data) {
                this.documents = await response.data.data;
                this.links = await response.data.links;
                this.meta = await  response.data.meta;
            } else {
                this.documents = await response.data;
                console.log("exception at getting documents");
            }
        }
        this.isFetching = false;
    }

    @action saveProgram = async (id: number, program: ImmigrationProgram) => {
        const response = await userService.updateItem(id === 0 ? 'immigration/programs/create' : ('immigration/programs/update/' + id), program);
        if (response.status === 201 || response.status === 200){
            this.programs = this.programs.filter(item => item.id !== id);
            let newProgram: ImmigrationProgram = response.data && response.data.data? response.data.data: response.data;
            this.programs.push(newProgram);
            return newProgram;
        }
        return null;
    }

    @action saveDocuments = async (id: number, document: FormFieldSend) => {
        const response = await userService.updateItem(id === 0 ? 'immigration/documents/create' : ('immigration/documents/update/' + id), document);
        if (response.status === 201 || response.status === 200){
            this.documents = this.documents.filter(item => item.id !== id);
            let newDocument: FormField = response.data && response.data.data? response.data.data: response.data;
            this.documents.push(newDocument);
            return newDocument;
        }
        return null;
    }

    @action checkExistDocumentSortOrder = async (sort_order: number, documentId: number) => {
        const response = await userService.fetchAll('immigration/documents/check-sort-order/'+documentId+'?sort_order=' + sort_order);
        if (response.status === 200){
            return !!response.data;
        }
        else return false;
    }

    @action checkExistProgramSortOrder = async (sort_order: number, programId: number) => {
        const response = await userService.fetchAll('immigration/programs/check-sort-order/'+programId+'?sort_order=' + sort_order);
        if (response.status === 200){
            return !!response.data;
        }
        else return false;
    }

    @action appendInPrograms = async (document_id: number, programs: {id: number, name: string}[]) => {
        console.log(programs.map((e) => e.id))
        const response = await userService.fetchAll('immigration/documents/add-references', {
            document_id: document_id,
            programs: programs.map((e) => e.id)
        });
        if (response.status === 200){
            return !!response.data;
        }
        else return false;
    }

    @action getDataForEditDocument = async (document_id: number) => {
        const response = await userService.fetchAll('immigration/documents/data-for-edit/'+document_id);
        if (response.status === 200){
            return response.data as EditDocumentRequest;
        }
        else return null;
    }

    @action getDataForEditPrograms = async (program_id: number) => {
        const response = await userService.fetchAll('immigration/programs/data-for-edit/'+program_id);
        if (response.status === 200){
            return response.data as EditProgramRequest;
        }
        else return null;
    }

    @action getDataForCreateDocument = async () => {
        const response = await userService.fetchAll('immigration/documents/data-for-new');
        if (response.status === 200){
            return response.data as CreateDocumentRequest;
        }
        else return null;
    }
    
    @action getDataForCreateProgram = async () => {
        const response = await userService.fetchAll('immigration/programs/data-for-new');
        if (response.status === 200){
            return response.data as CreateProgramRequest;
        }
        else return null;
    }

    @action getProgramById = async (id: number) => {
        const response = await userService.fetchAll('immigration/programs/get/' + id);
        if (response.status === 200){
            return response.data as ImmigrationProgram;
        }
        else return null;
    }

    @action savePDC = async (client_id: number, params: any) => {
        console.log(params);
        const response = await userService.updateItemNew('immigration/pdc/save/' + client_id, params);
        return response.status;
    }*/
}

const brandStore = new BrandStore()
export default brandStore;