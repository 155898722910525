import { observable, action, computed } from 'mobx'
import { Employee } from 'src/types/employee-type';
import userService from 'src/_services/user.service';

const initCl: Employee[] = [];

class EmployiesStore {

    @observable employies = initCl;
    @observable isFetching = false;

    @action fetchAll = async () => {

        if(this.isFetching === true) return;
        this.isFetching = true;
        let employees = await userService.fetchAll('employees')

        if (await employees.status === 200) {

            if (employees.data && employees.data.data) {
                this.employies = await employees.data.data;
            } else {
                this.employies = await employees.data;
            }
        }
        this.isFetching = false;
        return this.getEmployies;
    }

    @computed get getEmployies(){
        return this.employies;
    }

    @action addEmployee = (newEmp: Employee) => {
        this.employies.push(newEmp);
    }

    @action updateEmployee = async (id: number, userParams: any) => {

        const response = await userService.updateItem('employees/' + id, userParams);
        //console.log(response);

        if (response.status === 200){
            this.employies = this.employies.filter(item => item.id !== userParams.id);
            let newEmp = response.data && response.data.data? response.data.data: response.data;
            this.employies.push(newEmp);
            //console.log(this.employies.length);
        }
    }


    @action delEmployee = async (id: number) => {
        
        const response = await userService.deleteItem('employees/' + id);
        //console.log(this.employies.length);
        if (response.status === 204) {
            this.employies = this.employies.filter(item => item.id !== id);
            //console.log(this.employies.length);
        }
    }

    @action getAgents = async (type: string) => {
        return await userService.fetchAll('immigration/agents', {type});
    }
}

const employiesStore = new EmployiesStore()
export default employiesStore

