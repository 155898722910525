import {action, computed, observable} from 'mobx'
import FormsRequest from "../types/FormsRequest-type";
import ClientForm from "../types/client_form-type";
import userService from "../_services/user.service";
import {AxiosError, AxiosResponse} from "axios";
import {clientStore, userStore} from "./index";
import FormTypes from "../types/form-type";
import ClientFormField from "../types/client_form_fields-type";
import Lang, {getLang} from "../assets/lang";
import ClientFormStatus from "../types/client_form_status-type";
import FormField from "../types/form_field-type";
import {getDateTime} from "../pages/helpers/DateTimeHelpers";
import FormFieldValue from "../types/form_field_value-type";

interface IState {
  [key: string]: { value: string | number; id: number; attrid: number } | any;
}

const initForms = {} as FormsRequest;

class FormStore {
  @observable forms = initForms;
  @observable formsState: { [key: string]: any } = { passes: {} };
  @observable exlodedValidation: { id: number[]; group: number[]; tab: number[] } = {
    id: [],
    group: [],
    tab: [],
  };
  @observable stepsKey = 0;
  @observable state: IState = {}
  @observable hasChanged = false;
  @observable isViewErrors = false;
  cacheAsyncFormListValues = {} as {[key: string]: any};

  @action setState = (func?: Function | any) => {
    if(typeof func === "function") this.state = func(this.state);
    else this.state = func;
  }

  @observable formid = 1;

  @action setCurrentForm = (id: number) => {
    this.formid = id;
    this.formsState = { passes: {} };
    this.exlodedValidation = {
      id: [],
      group: [],
      tab: [],
    };
    this.state = {}
    this.globalStates();
    this.setFmState();
  }

  @action setCurrentFormByKey = (key: string) => {
    const form = this.forms.form.find(f => f.key === key);
    if(!form) return;
    this.setCurrentForm(form.id);
  }

  @action getFormIdByKey = (key: string) => {
    const form = this.forms.form.find(f => f.key === key);
    return form?.id || 0;
  }



  @action getFormStatusByKey(key: string) {
    const formId = this.forms.form.find((form) => form.key === key)?.id;
    if(formId) return this.forms.client_forms.find((clientForm) => clientForm.form_id === formId)?.status_id;
    return null;
  }

  @action getAsyncFormFieldValues = async (form_field_id: string|number, search: string, value_id = undefined) => {
    const request = await userService.postRequest('formFieldValue/getAsyncFieldValues', {form_field_id, search, lang: getLang(), value_id});
    return request.data;
  }

  @action getAsyncFormListValues = async (form_field_id: string|number, form_field_subfield_id: string|number, search: string, value_id = undefined) => {
    const cacheAsyncFormListValues = this.cacheAsyncFormListValues;
    const loading = 'loading...';
    const key = JSON.stringify({form_field_id, form_field_subfield_id, search, value_id});

    if(cacheAsyncFormListValues[key] === loading) {
      return new Promise((resolve, reject) => {
        const interval = {rejectCount: 300} as any; //300 = 1 minute waiting
        interval.value = setInterval(() => {
          if(cacheAsyncFormListValues[key] !== loading){
            clearInterval(interval.value);
            resolve(cacheAsyncFormListValues[key]);
          }
          interval.rejectCount--;
          if(interval.rejectCount < 0){
            clearInterval(interval.value);
            reject(loading);
          }
        }, 200);
      })
    }

    if(cacheAsyncFormListValues[key]) return cacheAsyncFormListValues[key];

    cacheAsyncFormListValues[key] = loading;
    const request = await userService.postRequest('formFieldValue/getAsyncListValues', {form_field_id, form_field_subfield_id, search, lang: getLang(), value_id});
    return cacheAsyncFormListValues[key] = request.data;
  }

  @action updateForm = async (url:string, props: any) => { return await userService.clientAddProp(url, props); }

  @computed get getForms() { return this.forms; }
  @action setForms = (newForms: FormsRequest) => { this.forms = newForms; }

  @action updateStatusForm = (formId: number, statusId: number) => {
    let existForm = formStore.getForms.client_forms.find((form: any)=> form.form_id === formId);
    if(!existForm){
      const postUrl = `clients/${clientStore.getCurrentClient.id}/client-forms`;
      let data = {
        form_id: formId,
        values: [],
      };
      userService
          .clientAddProp(postUrl, data)
          .then((response: AxiosResponse) => {
            if(response.status === 201){
              this._updateStatus(response.data.data, statusId);
            }
          });
    }
    else this._updateStatus(existForm, statusId);
  }

  @action _updateStatus = (form: ClientForm, status: number, comment: string = "status changed by admin") => {
    const url = `clients/${clientStore.getCurrentClient.id}/client-forms/${form.id}/change-status?_method=PUT`;
    const data = {
      to_status_id: status,
      commentary: comment,
    };
    this.updateForm(url, data).then((resp)=> {
      if (!this.forms.client_forms) this.forms.client_forms = [];
      let client_forms = this.forms.client_forms;
      let updatingForm = client_forms.find((item: any) => item.id === form.id);
      let append = false;
      if (updatingForm == null) {
        updatingForm = form;
        append = true;
      }
      updatingForm.status_id = status;
      updatingForm.updated_at = getDateTime(new Date());
      if (append) this.forms.client_forms.push(updatingForm);
    });
  }



  @action handleChange = (event: any, item?: any) => {
    let target = event.target ? event.target : event;
    if(target.id != null && target.id === 17) {
      target.value = target.value.filter((e: string) => e !== "");
    }
    const data = {
      [target.name]: {
        value: target.value,
        id: item.parent_form_field_id ? item.parent_form_field_id : item.id,
        attrid: item.attrId,
        type_id: item.type_id,
      },
    };

    this.setState((prevState: any) => ({
      ...prevState,
      ...data,
    }));
  };

  @action isJson = (str: any) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return isNaN(str);
  };

  @action getTheForm = (id?: number) => {
    return this.forms.form.find((form: FormTypes) => form.id === (id ? id : this.formid));
  };

  @action getClientForm = (form_id?: number | boolean) => {
    return this.forms.client_forms.find(
        (item: ClientForm) =>
            item.client_id === clientStore.getCurrentClient.id &&
            item.form_id === (form_id ? form_id : this.formid)
    );
  };

  @action handleClientFormFieldsStateValues = (
      newClientForm: ClientForm,
      oldClientFormFields: ClientForm | undefined
  ) => {
    const newData = {
      client_form_id: newClientForm.id,
    };
    const values = newClientForm.values.slice();

    return values.reduce(
        (
            tottal: any[],
            curr: { id: number; form_field_id: number; value: any }
        ) => {
          const itemCurr = oldClientFormFields
              ? oldClientFormFields.client_form_fields.find(
                  (item: ClientFormField) =>
                      item.id === curr.id
              )
              : false;

          let value;
          if (itemCurr && itemCurr.id) {
            itemCurr.value = curr.value;
            value = itemCurr;
          } else {
            value = { ...newData, ...curr };
          }
          tottal.push(value);
          return tottal;
        },
        []
    );
  };

  @action handleClientFormFieldsStateValuesForFile = (
      newClientForm: ClientForm,
      oldClientFormFields: ClientForm | undefined
  ) => {
    return newClientForm.values.slice().map(i => ({...i, client_form_id: newClientForm.id}));
  };

  @action handaleSubmit = (e?: any, items?: any, cbk?: CallableFunction) => {
    if (e) e.preventDefault();

    const clientFormFields = this.getClientForm();
    let postUrl: string;
    if (clientFormFields?.id) {
      postUrl = `clients/${clientStore.getCurrentClient.id}/client-forms/${clientFormFields.id}?_method=PUT`;
    } else {
      postUrl = `clients/${clientStore.getCurrentClient.id}/client-forms`;
    }

    let itemCombined = items ? { ...this.state, ...items } : this.state;

    let data = {
      form_id: this.formid,
      values: buildFormData(itemCombined),
    };
    console.log(itemCombined, items, " data to send:: ", data);
    if (!Object.keys(itemCombined).length) {
      if (cbk) cbk({ status: "INFO", message: Lang.get("You Did'nt Make Any Changes!") });
      return false;
    }

    userService
        .clientAddProp(postUrl, data)
        .then((response: AxiosResponse) => {
          if (response.status === 200 || response.status === 201) {
            const clientForm = response.data.data;

            const valuse = this.handleClientFormFieldsStateValues(
                clientForm,
                clientFormFields
            );
            if (!clientFormFields) {
              // new record
              clientForm.client_form_fields = valuse;
              delete clientForm.values;
              this.forms.client_forms.push(clientForm);
            } else {
              // update exisst record
              clientFormFields.client_form_fields = valuse;
            }

            if (cbk) cbk({ status: "SUCCESS" }, valuse);
          } else {
            if (cbk) cbk({ status: "FAIL", message: Lang.get("Unknown server error") }, false);
          }
        })
        .catch((error: AxiosError) => {
          if (cbk) cbk({ status: "FAIL", message: error.message }, false);
        });
  };

  @action handaleSubmitForFile = (currentFromId: number, e?: any, items?: any, cbk?: CallableFunction) => {
    if (e) e.preventDefault();

    const clientFormFields = this.getClientForm(currentFromId);
    let postUrl: string;
    if (clientFormFields?.id) {
      postUrl = `clients/${clientStore.getCurrentClient.id}/client-forms/${clientFormFields.id}?_method=PUT`;
    } else {
      postUrl = `clients/${clientStore.getCurrentClient.id}/client-forms`;
    }

    let itemCombined = items ? { ...this.state, ...items } : this.state;

    let data = {
      form_id: this.formid,
      values: buildFormData(itemCombined),
    };
    console.log(itemCombined, items, " data to send:: ", data);
    if (!Object.keys(itemCombined).length) {
      if (cbk) cbk({ status: "INFO", message: Lang.get("You Did'nt Make Any Changes!") });
      return false;
    }

    userService
        .clientAddProp(postUrl, data)
        .then((response: AxiosResponse) => {
          if (response.status === 200 || response.status === 201) {
            const clientForm = response.data.data;

            const valuse = this.handleClientFormFieldsStateValuesForFile(
                clientForm,
                clientFormFields
            );

            if (!clientFormFields) {
              // new record
              clientForm.client_form_fields = valuse;
              delete clientForm.values;
              this.forms.client_forms.push(clientForm);
            } else {
              // update exisst record
              clientFormFields.client_form_fields = valuse as ClientFormField[];
            }
            if (cbk) cbk({ status: "SUCCESS" }, valuse);

          } else {
            if (cbk) cbk({ status: "FAIL", message: Lang.get("Unknown server error") }, false);
          }
        })
        .catch((error: AxiosError) => {
          if (cbk) cbk({ status: "FAIL", message: error.message }, false);
        });
  };

  @action nameToSnakeCase = (name: string) => {
    if (!name || name.length === 0)
      return "";

    name = name.toLowerCase()
        .replace(" ", "-")
        .replace(/[^a-zA-Z0-9\s]+/g, "");

    let splitText = name.trim().split(" ");

    if (splitText.length === 0)
      return name;

    splitText = splitText.length > 3 ? splitText.slice(0) : splitText;

    return splitText.reduce((total, curr) => total.length >= 1 ? total + "_" + curr : (total = curr), "");
  };

  @action filterItem = (key: string, keyField: string, itemId: number) => {
    return this.forms[key].filter((items: any) => items[keyField] === itemId);
  };

  @action getDropDownValue = (formFieldId: number, formFieldSubfieldId: number|null = null) => {
    return this.forms.form_field_value.filter((item) => item.form_field_id === formFieldId && item.form_field_subfield_id === formFieldSubfieldId );
  };

  /*@action educationLevelOptions = (
    keyItems = "education_level",
    value = "name",
    itemIdx?: number
  ) => {
    const items = this.forms[keyItems].filter(
      (item: any) => item.form_field_id === itemIdx
    );

    return items.map((item: any, idx: number) => {
      const value: string =
        item.name && item.name !== "" ? item.name : item.value;
      const haveChoose = value.toLowerCase().indexOf("choose") > -1;

      return (<React.Fragment key={idx + "_" + item.id + "_" + value}>
        {(idx === 0 && haveChoose) ? (
          <option value="">{Lang.get("Please Select")}</option>
        ) : (idx > 0 && haveChoose) ? (
          null
        ) : (idx === 0 && !haveChoose) ? (<>
            {item.id !== 61 && <option value="">{Lang.get("Please Select")}</option>}
            <option data-set={value} value={item.id}>
              {Lang.get(value)}
            </option>
          </>) :
          (<option data-set={value} value={item.id}>
            {Lang.get(value)}
          </option>)}
      </React.Fragment>)
    });
  };*/

  /*@action getDropDownOptions = (items: any[], itemIdx: number) => {
    return items.map((item: any, idx: number) => {
      const value: string =
        item.name && item.name !== "" ? item.name : item.value;
      const haveChoose = value.toLowerCase().indexOf("choose") > -1;


      return (<React.Fragment key={idx + "_" + item.id + "_" + value}>
        {(idx === 0 && haveChoose) ? (
          <option value="">{Lang.get("Please Select")}</option>
        ) : (idx > 0 && haveChoose) ? (
          null
        ) : (idx === 0 && !haveChoose) ? (<>
            {item.id !== 61 && <option value="">{Lang.get("Please Select")}</option>}
            <option data-set={value} value={item.id}>
              {Lang.get(value)}
            </option>
          </>) :
          (<option data-set={value} value={item.id}>
            {Lang.get(value)}
          </option>)}
      </React.Fragment>)
    });
  };*/

  @action authIsAdmin = () => userStore.isAdmin;
  @action authIsEmployee = () => userStore.isEmployee;
  @action authIsClient = () => userStore.isClient;

  @action getApprovedStatus = () => {
    return this.forms.client_form_status.find((item: any) => item.id === 3);
  };

  @action approveDocs = (
      cbk: CallableFunction,
      cliFid?: number,
      update?: boolean
  ) => {
    const clientFm = this.getClientForm() as ClientForm;

    if (!clientFm) {
      cbk({ status: "INFO", message: Lang.get("You Didn't Finish Fill Out The Document.") });
      return;
    }
    if (this.authIsClient() && clientFm.status_id === 3) {
      cbk({ status: "INFO", message: Lang.get("The Document Allready Approved.") });
      return;
    }

    const clientFormId = cliFid ? cliFid : clientFm.id;
    const clientSt = this.getApprovedStatus() as ClientFormStatus,
        urlVerify = `clients/${clientStore.getCurrentClient.id}/client-forms/${clientFormId}/change-status?_method=PUT`,
        data = {
          to_status_id:
              (this.isApproved() && this.authIsEmployee()) || (this.isApproved() && this.authIsAdmin())
                  ? 1
                  : this.authIsEmployee() && this.authIsAdmin() && clientSt
                      ? clientSt.id
                      : 2,
          commentary: "click submit",
        };

    this.updateForm(urlVerify, data).then((response) => {
      if (response.status === 200) {
        clientFm.status_id = response.data.data.status_id;
        cbk({ status: "SUCCESS", message: Lang.get("Vrification was send Successfully.") });
        return true;
      }
      cbk({ status: "INFO", message: Lang.get("Something went wrong.")});
    });
  };

  @action isApproved = () => {
    const client_forms = this.getClientForm() as ClientForm;
    const approvedId = (client_forms != null ? client_forms.status_id : 1);
    if(approvedId === 1) return !userStore.isEditNotSet;
    else if(approvedId === 2) return !userStore.isEditSubmitted;
    else return !userStore.isEditVerified;
  };

  @action getClientFormFields = (field_id: number,form_id?: number | boolean) => {
    const cliForm = this.getClientForm(form_id ? form_id : false) as ClientForm;
    if (cliForm) {
      const client_form_fields = cliForm.client_form_fields.filter(
          (field: any) =>
              cliForm.id === field.client_form_id &&
              field.form_field_id === field_id
      );
      return client_form_fields;
    }
    return [];
  };

  @action getDefaultValue = (field_id: number, form_id?: number): ClientFormField[] | string[] | string => {

    const form_fields: any[] = this.getClientFormFields(field_id,form_id ? form_id : false);
    if (form_fields && form_fields.length) {
      const field = form_fields[0];
      return this.isJson(field.value) ? JSON.parse(field.value) : field.value;
    }
    return "";
  };


  @action getFileValues = (field_id: number, form_id?: number): ClientFormField[] | string[] | string => {
    const form_fields: any[] = this.getClientFormFields(field_id,form_id ? form_id : false);
    if (form_fields && form_fields.length) {
      return form_fields.map((field: any) => this.isJson(field.value) ? JSON.parse(field.value) : field.value);
    }
    return "";
  };

  @action getUniqueId = (strBegin: string = "a") => {
    return strBegin + Math.random().toString(36).substring(7);
  };

  @action isExelode = (id: number, groupId: number, tabId: number) => {
    return (
        (id > 0 &&      this.exlodedValidation.id.indexOf(id) > -1) ||
        (groupId > 0 && this.exlodedValidation.group.indexOf(groupId) > -1) ||
        (tabId > 0 &&   this.exlodedValidation.tab.indexOf(tabId) > -1) ||
        (id > 0 &&      !this.forms.form_field.find((e) => e.id === id)?.is_required) ||
        (clientStore.getCurrentClient.is_single && this.forms.form_field.find((e) => e.id === id)?.is_spouse)
    );
  };

  @action setGlobStateValue = (
      fieldElement: any,
      cliForm: any,
      formName: string,
  ) => {

    const exloded = this.isExelode(
        fieldElement.id,
        fieldElement.form_field_group_id,
        fieldElement.tab_id || -1
    );

    if (exloded || fieldElement.id === 79) return true;

    const fieldName = this.nameToSnakeCase(fieldElement.name + fieldElement.id);

    const value = cliForm
        ?
        (Array.isArray(cliForm.client_form_fields)
            ? cliForm.client_form_fields.filter((field: any) => cliForm.id === field.client_form_id && field.form_field_id === fieldElement.id)
            : false)
        : false;

    if (!this.formsState[formName][fieldName])
      this.formsState[formName][fieldName] = {};

    let isValid = typeof value === "string" ? value !== "" : Array.isArray(value) && value.length ?
        value.find((subItem: any) =>
            subItem.value !== null &&
            subItem.value !== "" &&
            subItem.value !== "null"
        )
        : false;

    if (isValid && typeof isValid === "object") isValid = isValid.value;
    if (isValid && Array.isArray(isValid)) isValid = isValid.length !== 0;
    if (isValid && isValid !== "null" && isValid !== null && isValid !== undefined) {
      this.formsState[formName]["done"] = true;
      this.formsState[formName][fieldName] = {
        fieldName: fieldName,
        field_id: fieldElement.id,
        type_id: fieldElement.type_id,
        sort_order: fieldElement.sort_order,
        value: value,
        done: true,
        isValid: isValid,
      };
      return true;
    } else {
      this.formsState[formName].done = false;
      this.formsState[formName][fieldName] = {
        fieldName: fieldName,
        field_id: fieldElement.id,
        type_id: fieldElement.type_id,
        sort_order: fieldElement.sort_order,
        value: value,
        done: false,
        isValid: false,
      };
      return false;
    }
  };

  @action globalStates = () => {
    this.forms.form.forEach((formElement) => {
      const formName = this.nameToSnakeCase(formElement.name + formElement.id);

      let formFields: FormField[] = this.filterItem("form_field","form_id", formElement.id);

      const includeSpouseId = [66];

      if(clientStore.getCurrentClient.is_single) {
        formFields = formFields.filter((e) =>
            e.name.toLowerCase().indexOf("spouse") === -1 || includeSpouseId.indexOf(e.id) !== -1);
      }
      // eslint-disable-next-line
      let done = true;
      this.formsState[formName] = {};

      const clientFormValues = this.getClientForm(formElement.id);

      formFields.forEach((fieldElement: FormField, fieldIdx: number) => {
        if (fieldIdx === 0) done = true;

        if (fieldElement.id === 79) { // ,79,80,81,82, 
          const value = clientFormValues ?
              clientFormValues.client_form_fields.find((field: any) =>
                  field.form_field_id === fieldElement.id && field.value === "216") : false;

          const isValid = (value && typeof value === "object") ? +(value.value) : false;

          if (isValid) {

            [80, 81, 82].forEach((itemNum: number) => {
              const idxNum = this.exlodedValidation.id.indexOf(itemNum);
              if (idxNum > -1) {
                this.exlodedValidation.id.splice(idxNum, 1);
              }

            });
            console.warn(this.exlodedValidation, " pos: ");
          } else {
            [80, 81, 82].forEach((itemNum: number) => {
              const idxNum = this.exlodedValidation.id.indexOf(itemNum);
              if (idxNum < 0) this.exlodedValidation.id.push(itemNum);
            });
          }
        }

        const isExluded = this.isExelode(fieldElement.id, fieldElement.form_field_group_id || -1, fieldElement.tab_id || -1);

        if (!isExluded) {
          const res = this.setGlobStateValue(
              fieldElement,
              clientFormValues,
              formName,
          );
          if (!res) done = false;
        }
      }); // END forEach FOR LOOP
      this.formsState[formName].done = done;
      if (done && clientFormValues && clientFormValues.status_id > 1) {
        this.formsState.passes[formName] = true;
      } else {
        this.formsState.passes[formName] = false;
      }
    })

    console.warn("FORM STATE: ", this.formsState);
  };

  @action getGlobState = () => this.formsState;

  @computed get getExplodedValidation() { return this.exlodedValidation; }

  @action setGlobState = (key: string, idField: any) => {
    this.formsState[key] = idField;
  };

  @action setFmState = () => {
    const theForm = this.forms.form;
    const len = 6; //theForm.length / 2;
    let isApproved = true;

    for (let idx = 0; idx < len; idx++) {
      // MAIN FOR LOOP
      const formElement = theForm[idx];
      const clientFm = this.getClientForm(formElement.id) as ClientForm;

      if (!clientFm || clientFm.status_id < 2) {
        isApproved = false;
        this.stepsKey = 0;
        break;
      } else if (clientFm.status_id > 2) {
        this.stepsKey = this.stepsKey + 2;
      } else {
        this.stepsKey++;
      }
    }

    if (!isApproved) {
      // setStepsCurrent(steps[stepsKey]);
    } else if (this.stepsKey === 12) {
      this.stepsKey = 2;
      // setStepsCurrent(steps[stepsKey]);
    } else {
      this.stepsKey = 1;
      // setStepsCurrent(steps[stepsKey]);
    }
  }

  @action getFmStae = () => this.stepsKey;

  @action excludeValidationByFieldId = (add: number[], remove: number[]) => {
    let addNotFound: any[] = [];
    add.forEach((e) => {
      if(this.exlodedValidation.id.indexOf(e) === -1) addNotFound.push(e);
    });
    this.exlodedValidation.id.push(...addNotFound);

    remove.forEach((e) => {
      if(this.exlodedValidation.id.indexOf(e) > -1) {
        this.exlodedValidation.id.splice(this.exlodedValidation.id.indexOf(e), 1);
      }
    })
  }

  @action getFFVById = (ffv_id: any) => {
    if(!ffv_id) return undefined;
    return this.forms.form_field_value.find((ffv: FormFieldValue) => ffv.id === parseInt(ffv_id.toString()));
  }

  @action getFFVsByIds = (ffv_ids: any): (FormFieldValue | undefined)[] | undefined => {
    if(!ffv_ids || !Array.isArray(ffv_ids)) return undefined;

    return ffv_ids.map((ffv_id) => {
      return this.forms.form_field_value.find((ffv: FormFieldValue) => ffv.id === parseInt(ffv_id.toString()));
    })
  }














}

const formStore = new FormStore()
export default formStore







const buildFormData = (state: any) => {
  return Object.keys(state).reduce(
      (accur: { form_field_id: number; value: any } | any, current: string) => {
        let ob: any = {};
        const booleanVal = state[current].type_id === 4 && (state[current].value === "Yes") ? 1 : 0;
        const vals = state[current].type_id === 4 ? booleanVal : state[current].value;

        if (state[current]?.attrid === 12) {
          let haveVal = accur.find(
              (item: any) => item.form_field_id === state[current].parentid
          );

          if (!haveVal) ob["form_field_id"] = state[current].parentid;
          ob["value"] = [
            {
              id: state[current].id,
              value: vals,
            },
          ];

          haveVal && Array.isArray(haveVal["value"])
              ? haveVal["value"].push({
                id: state[current].id,
                value: vals,
              })
              : accur.push(ob);
        } else if(state[current].type_id === 6 && state[current].ind_type_id){
          ob = { form_field_id: state[current].id, value: vals, type_id: state[current].ind_type_id };
          accur.push(ob);
        } else {
          ob = { form_field_id: state[current].id, value: vals };
          accur.push(ob);
        }
        return accur;
      },
      []
  );
};