import {observable, computed, action} from 'mobx'
import User, {UserRole} from '../types/user-type'
import Role from '../types/user_role_type'
import Permission from '../types/user_permission_type'
import authService from 'src/_services/auth.service';
import userService from 'src/_services/user.service';
import {appStore, clientStore, pdcStore} from "./index";

const initUser: User | null = null;
const initUsers: User[] = [];
const initPermissions: Permission[] | null = [];
const initRoles: Role[] | null = [];

class UserStore {
    @observable user = initUser;
    @observable users = initUsers;
    @observable permissions = initPermissions;
    @observable roles = initRoles;
    @observable loading = false;
    @observable isFetching = false;

    constructor() {
        (async () => {
            if (this.isAuthorized) return;
            this.loading = true;
            const token = authService.getCurrentTokn();
            if (token) {
                const response = await userService.getAdminBoard().catch(err => err.response); // getClientBoard, getAdminBoard, getEmployeeBoard
                if (response && response.status === 200) {
                    let user = response.data;
                    this.setUser(user);
                    this.loading = false;
                } else if (response && response.status === 401) {
                    this.logout();
                    this.loading = false;
                }
            }
            else {
                this.loading = false;
            }
        })();
    }
    
    @computed get isAuthorized() {
        return !!this.user;
    }

    @computed get getUser() {
        return this.user;
    }

    @computed get getUsers() {
        return this.users;
    }

    @computed get isAdmin() {
        return this.isAuthorized && this.user!.roles![0].id === UserRole.Admin;
    }

    @computed get isClient() {
        return this.isAuthorized && (
            this.user!.roles![0].id === UserRole.Client ||
            this.user!.roles![0].id === UserRole.ClientVisitor ||
            this.user!.roles![0].id === UserRole.ClientImmigrant
        );
    }

    @computed get isEmployee() {
        return this.isAuthorized && this.user!.roles![0].id === UserRole.Employee;
    }

    @computed get isViewPowerLink() {
        return (this.isAuthorized && (
            this.user!.roles![0].id === UserRole.AccountManager ||
            this.user!.roles![0].id === UserRole.RCIC ||
            this.user!.roles![0].id === UserRole.CustomerSupportManager
        )) || this.isAdmin;
    }

    @computed get isViewAllClients() {
        return (this.isAuthorized && (
            this.user!.roles![0].id === UserRole.AccountTeamLeader ||
            this.user!.roles![0].id === UserRole.ComplianceManager ||
            this.user!.roles![0].id === UserRole.SupportTeamLeader
        )) || this.isAdmin;
    }

    @computed get isChangeStatus() {
        return (this.isAuthorized && (
            this.user!.roles![0].id === UserRole.ComplianceManager ||
            this.user!.roles![0].id === UserRole.SupportTeamLeader
        )) || this.isAdmin;
    }

    @computed get isEditNotSet() {
        return (this.isAuthorized && (
            this.user!.roles![0].id === UserRole.Client ||
            this.user!.roles![0].id === UserRole.ClientVisitor ||
            this.user!.roles![0].id === UserRole.ClientImmigrant ||
            this.user!.roles![0].id === UserRole.AccountManager ||
            this.user!.roles![0].id === UserRole.AccountTeamLeader ||
            this.user!.roles![0].id === UserRole.ComplianceManager ||
            this.user!.roles![0].id === UserRole.CustomerSupportManager ||
            this.user!.roles![0].id === UserRole.SupportTeamLeader
        )) || this.isAdmin;
    }

    @computed get isEditSubmitted() {
        return (this.isAuthorized && (
            this.user!.roles![0].id === UserRole.AccountManager ||
            this.user!.roles![0].id === UserRole.AccountTeamLeader ||
            this.user!.roles![0].id === UserRole.ComplianceManager ||
            this.user!.roles![0].id === UserRole.CustomerSupportManager ||
            this.user!.roles![0].id === UserRole.SupportTeamLeader
        )) || this.isAdmin;
    }

    @computed get isEditVerified() {
        return (this.isAuthorized && (
            this.user!.roles![0].id === UserRole.ComplianceManager ||
            this.user!.roles![0].id === UserRole.SupportTeamLeader
        )) || this.isAdmin;
    }

    @computed get isUploadEligibilityReport() {
        return (this.isAuthorized && (
          this.user!.roles![0].id === UserRole.AccountManager ||
          this.user!.roles![0].id === UserRole.AccountTeamLeader
        )) || this.isAdmin;
    }

    @computed get isPublishAbout() {
        return (this.isAuthorized && (
          this.user!.roles![0].id === UserRole.AccountManager ||
          this.user!.roles![0].id === UserRole.AccountTeamLeader
        )) || this.isAdmin;
    }

    @computed get isRePublishAbout() {
        return this.isAdmin;
    }

    @computed get isPublishAdditionalDocuments() {
        return (this.isAuthorized && (
          this.user!.roles![0].id === UserRole.AccountManager ||
          this.user!.roles![0].id === UserRole.AccountTeamLeader ||
          this.user!.roles![0].id === UserRole.CustomerSupportManager ||
          this.user!.roles![0].id === UserRole.SupportTeamLeader
        )) || this.isAdmin;
    }

    @computed get isCanUsePDCEditor() {
        return (this.isAuthorized && (
          this.user!.roles![0].id === UserRole.RCIC ||
          this.user!.roles![0].id === UserRole.ComplianceManager ||
          this.user!.roles![0].id === UserRole.CustomerSupportManager
        )) || this.isAdmin;
    }

    @computed get isCanViewImmigrationPackage() {
        return (this.isAuthorized && (
          this.user!.roles![0].id === UserRole.Client ||
          this.user!.roles![0].id === UserRole.ClientVisitor ||
          this.user!.roles![0].id === UserRole.ClientImmigrant ||
          this.user!.roles![0].id === UserRole.AccountManager ||
          this.user!.roles![0].id === UserRole.AccountTeamLeader ||
          this.user!.roles![0].id === UserRole.ComplianceManager ||
          this.user!.roles![0].id === UserRole.CustomerSupportManager ||
          this.user!.roles![0].id === UserRole.SupportTeamLeader
        )) || this.isAdmin;
    }
    
    @computed get isCanReviewER() {
        return (this.isAuthorized && (
          this.user!.roles![0].id === UserRole.RCIC
        )) || this.isAdmin;
    }
    
    @computed get isCanViewHistoryLog() {
        return (this.isAuthorized && this.user!.roles![0].id !== UserRole.RCIC) || this.isAdmin;
    }

     @computed get isCanApproveER() {
        return (this.isAuthorized && (
            this.user!.roles![0].id !== UserRole.RCIC &&
            this.user!.roles![0].id !== UserRole.Client
        ));
    }

    @computed get employeeAssessmentType() {
        return this.user?.employee?.assessment_type;
    }
    
    @computed get employeeAssessmentStatus() {
        return this.user?.employee?.assessment_status;
    }

    @action setUser(userParams: any) {
        this.user = userParams as User;
        this.permissions = userParams.permissions;
        this.roles = userParams.roles;
    }

    @action logout() {
        this.user = initUser;
        clientStore.clearAllData();
        pdcStore.clearAllData();
        appStore.clearAllData();
        let lang = localStorage.getItem("lang");
        localStorage.clear();
        if(lang) localStorage.setItem("lang", lang);
    }

    @action fetchAll = async () => {
        if (this.isFetching) return;
        this.isFetching = true;
        let users = await userService.fetchAll('users');
        if (users.status === 200) {
            if (users.data && users.data.data) {
                this.users = await users.data.data;
            } else {
                this.users = await users.data;
            }
        }
        this.isFetching = false;
        return this.users;
    }

    @action updateUser = async (id: number, updatedUser: User) => {
        const response = await userService.updateItem('users/' + id, updatedUser as any);
        if (response.status === 200) {
            this.users = this.users.filter(item => item.id !== updatedUser.id);
            let newUser = response.data && response.data.data ? response.data.data : response.data;
            this.users.push(newUser);
        }
    }

    @action delUser = async (id: number) => {
        const response = await userService.deleteItem('users/' + id);
        if (response.status === 204) {
            this.users = this.users.filter(item => item.id !== id);
            console.log(this.users.length);
        }
    }

    @action getObjectId = async (client_user_id: number) => {
        const response = await userService.fetchAll('crm-request/getObjectId', {
            client_id: client_user_id
        });
        if (response.status === 200) {
            return response.data as string;
        }
        return "";
    }

    @action demoExpiredSendEmailToAgent = (params: any) => {
        return userService.postRequest(`users/${userStore.getUser?.id}/demo-expired-contact-to-agent`, params)
            .then((result) => {
                return result.data;
            }).catch((err) => {
                throw err.response.data;
            })
    }

    @action paymentDeclined = () => {
        return userService.postRequest(`users/${userStore.getUser?.id}/paymentDeclined`)
            .then((result) => {
                return result.data;
            }).catch((err) => {
                throw err.response.data;
            })
    }
}

const userStore = new UserStore()
export default userStore

