import React, { Component, ErrorInfo, ReactNode } from 'react'

const ErrorContent = ({ error, ...props }: any) => (
  <div {...props}>
    <h1>Error</h1>
    <p>{error.message ? error.message : error}</p>
  </div>
)

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
  errorText: string;
}

class ErrorBoundary extends Component<Props, State> {
  state = {
    hasError: false,
    errorText: 'Something went wrong'
  }
  
  static getDerivedStateFromError(error: string) {
    return { hasError: true, errorText: error }
  }
  
  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    //alert("Error was occur");
    //console.log(error, errorInfo);
  }
  
  render() {
    const { children } = this.props
    const isErrorPage = window.location.pathname === '/error'
    const is404 = window.location.pathname === '/404'
    if (is404) return <ErrorContent error={'404'} />
    if (isErrorPage || this.state.hasError) return <ErrorContent error={this.state.errorText} />
    
    return children
  }
}

export default ErrorBoundary as any
