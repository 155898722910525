import React, { FC } from "react";

import ErrorBoundary from "src/core/ErrorBoundary";
import Router from "src/core/Routes";
import { ThemeProvider } from "styled-components";
import { theme, muiTheme } from "src/styles/theme";

import * as stores from "src/store";
import {StoreContext} from "src/store";
import "./app.css"
import {MuiThemeProvider} from '@material-ui/core/styles';
import Toastify from "src/pages/components/Toastify";

const App: FC = () => (
  <ErrorBoundary>
      <StoreContext.Provider value={stores}>
        <ThemeProvider theme={theme}>
          <MuiThemeProvider theme={muiTheme}>
            <Router />
            <Toastify />
          </MuiThemeProvider>
        </ThemeProvider>
      </StoreContext.Provider>
  </ErrorBoundary>
);

export default App;
